import { useLayoutsExtended } from '@tunasong/plugin-lib'
import { AppChrome, EntityChrome, ErrorMessage } from '@tunasong/tunasong-ui'
import { useParams, useSearchParams } from '@tunasong/ui-lib'
import { graphHooks } from '@tunasong/graph-lib/react'

/** Router wrapper for the AppChrome */
export const RouterAppChrome = () => {
  const { entityId } = useParams()
  const [searchParams] = useSearchParams()
  const layoutName = searchParams.get('layout') ?? undefined

  const { entity, error } = graphHooks.useEntity(entityId)
  const layouts = useLayoutsExtended(entity)

  const layout = layouts.find(layout => layout.name === layoutName) ?? layouts[0]

  const showContainer = layout?.container !== false
  const container = layout?.container ? layout.container : {}
  const showHeader = showContainer && container?.showHeader !== false
  const showNavigationBar = layout?.showNavigationBar
  const showSidebar = container?.showSidebar !== false

  return (
    <AppChrome layouts={layouts} currentLayout={layoutName} showNavigationBar={showNavigationBar}>
      {!error ? (
        <EntityChrome
          entity={entity}
          layoutName={layoutName}
          showSidebar={showSidebar}
          showHeader={showHeader}
          showContainer={showContainer}
        />
      ) : (
        <ErrorMessage rtkQueryError={error} />
      )}
    </AppChrome>
  )
}
